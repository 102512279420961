import React, { useContext } from 'react'

import { GoogleOAuthProvider } from '@react-oauth/google'

import config from 'constants/config'

import notifier from 'tools/Notify'

export function SignonProvider({ children }) {
  const [, notifyDispatch] = useContext(notifier.Context)
  return (
    <GoogleOAuthProvider
      clientId={config.auth.google.appId}
      onScriptLoadError={(error) =>
        notifier.error(
          notifyDispatch,
          'Federated Authentication with Google is blocked by extension'
        )
      }
    >
      {children}
    </GoogleOAuthProvider>
  )
}
