/* Randomize array in-place using Durstenfeld shuffle algorithm */
export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1))
    var temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}
export function shuffle(array) {
  const newArray = [...array]
  shuffleArray(newArray)
  return newArray
}

// using lists for ordered sets
export function setAdd(list1, component) {
  if (!list1.includes(component)) {
    list1.push(component)
  }
  return list1
}

export function setRemove(list1, component) {
  return list1.filter((i) => i !== component)
}

export function setMerge(list1, list2) {
  list2.forEach((elem) => setAdd(list1, elem))
  return list1
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(range(0, 4, 1), [ 0, 1, 2, 3 ])
 * t.deepEqual(range(0, 4), [ 0, 1, 2, 3 ])
 * t.deepEqual(range(0, 4, 2), [ 0, 2 ])
 * t.deepEqual(range(0, 5, 2), [ 0, 2, 4 ])
 * ```
 */
export function range(start, stop, step = 1) {
  return Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step)
}

////////////////////////////////////////////////////////////////////////////////
export function removeItem(list, item) {
  return list.filter((i) => i.id !== item.id)
}

export function upsertItem(list, item) {
  return removeItem(list, item).concat(item)
}

/*
 * naive implementation don't use for large lists
 *
 * @doctests
 *
 * ```js
 * t.deepEqual(dedupe([]), [])
 * t.deepEqual(dedupe([1,2]), [1,2])
 * t.deepEqual(dedupe([1,2,2]), [1,2])
 * t.deepEqual(dedupe([2,1,2,4,1]), [2,1,4])
 * ```
 */
export const dedupe = (xs) => {
  if (xs.length <= 1) return xs
  return xs.slice(0, 1).concat(dedupe(xs.slice(1).filter((x) => x !== xs[0])))
}

/*
 * @doctests
 *
 * ```js
 * t.deepEqual(toDictOn([{id: 'a'}, {id: 'b'}, {id: 'c'}], 'id'), {a: {id: 'a'}, b: {id: 'b'}, c: {id: 'c'}})
 * ```
 */
export const toDictOn = (arr, key) =>
  arr.reduce((acc, obj) => {
    acc[obj[key]] = obj
    return acc
  }, {})
