import React, { useContext } from 'react'
import { MenuButton, MenuList } from 'react-menu-list'

import { MenuLink, NavLink } from 'tools/Links'
import Activity from 'tools/NavTabs/Activity'

import { avatarUrl } from 'components/User/tools/Picture'
import GlobalContext from 'reducer/global'

import style from './index.module.scss'

const offset = 'mr3 br2'
export function NavBar() {
  const [
    {
      nav: { bar, menu },
      saving,
      page,
      user
    }
  ] = useContext(GlobalContext)
  const { url } = avatarUrl(user)
  const hasActivity = menu.find((i) => i.activity) ? true : false
  let title = page.title
  if (title === 'Revenant') {
    title = ''
  } else if (title) {
    title = ` — ${title}`
  }
  return (
    <>
      <div className={`navbar ${style.navbar} ma0 pa1 flex-between`}>
        <a
          href="/"
          target="_self"
          className={`navlink ${style.navlink} ${offset} hover-hilite ml3 mr3 f4`}
        >
          REVENANT
        </a>
        <div className="f5 mr-auto">{title}</div>
        {saving ? <div className="ml3 gray throb f2">Saving...</div> : null}
        {bar.map((i, x) => (
          <BarElement key={i.label} elem={i} offset={offset} user={user} />
        ))}
        {user.authStatus === 'unknown' ? (
          <NavLink
            to="/signon"
            className={`navlink ${style.navlink} ${offset} hover-hilite`}
            center={true}
            logOrigin="SignOn"
            icon={
              <div className="flex flex-column items-center fw3 f2">
                <i className="fas fa-sign-in-alt mb1 f4" />
                Sign In
              </div>
            }
          />
        ) : (
          <MenuButton
            positionOptions={{
              position: 'bottom',
              vAlign: 'top',
              hAlign: 'left'
            }}
            menu={
              <div
                className={`pv1 pv2-m pv2-l white mt1 navbar-bg menu ${offset}`}
              >
                <MenuList>
                  {menu.map((i) => {
                    if (i.authz && !user.can(i.authz)) {
                      return null
                    }
                    if (i.divider) {
                      return <div key={i.id} className="bt b--gray" />
                    } else {
                      return (
                        <MenuLink
                          key={i.label}
                          to={i.to}
                          label={
                            <div className="relative f3">
                              {i.label}
                              <Activity show={i.activity} offset={true} />
                            </div>
                          }
                          origin={i.label}
                        />
                      )
                    }
                  })}
                </MenuList>
              </div>
            }
            className={`navlink ${style.navlink} ${offset} clear relative hover-hilite`}
          >
            <div className="flex flex-column items-center fw3 f2">
              <div className={style.microPic}>
                <img src={url} alt="" />
              </div>
              <div className="flex-items">
                Me <i className="ml2 fas fa-chevron-down" />
              </div>
              <Activity show={hasActivity} />
            </div>
          </MenuButton>
        )}
      </div>
    </>
  )
}

function BarElement({ elem, offset, user }) {
  if (elem.authz && !user.can(elem.authz)) {
    return null
  }
  if (elem.target) {
    return (
      <a
        href={elem.to}
        target={elem.target}
        className={`navlink ${style.navlink} ${offset} hover-hilite`}
      >
        <div className="flex flex-column items-center fw3 f2 relative">
          <i className={`fas fa-${elem.icon} mb1 f4`} />
          {elem.label}
        </div>
      </a>
    )
  }
  return (
    <NavLink
      key={elem.label}
      to={elem.to}
      center={true}
      label={
        <div className="flex flex-column items-center fw3 f2 relative">
          <i className={`fas fa-${elem.icon} mb1 f4`} />
          <Activity show={elem.activity} />
          {elem.label}
        </div>
      }
      className={`${offset} hover-hilite`}
    ></NavLink>
  )
}

export default NavBar
