// import SiteLogo from 'app/codex/Root/SiteLogo'
// import { Link } from 'tools/Links'
import React from 'react'

import config from 'constants/config'

import style from './index.module.scss'

// theme-bg could be put in here, but it looks odd
function Footer(props) {
  return (
    <div className={`footer ${style.footer} pa3`}>
      <div className="flex-center flex-column mt5 pa2 b mb4">
        <div className="flex-center gray mb4 mt4 f2">
          <div className="f2">
            <a href="/legal/" target="_self" className="flex-items">
              {config.name} &copy; {new Date().getFullYear()}, &trade;{' '}
              {config.nameLegal}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
