// import React from 'react'

// TODO: update to allow more variants, such as off-host and on-host
export const isLocalDev = process.env.NODE_ENV === 'development'

const config = {
  // note: Lane was used in logging events, but if we're not going to use it,
  // we should remove it. -BJG
  // lane: "p",
  build: "202311221421",
  app: "https://api.revenant.studio",
  ws: '/socket',
  graphql: '/graphql', // @GRAPHQL@
  authapi: '/authx/v1/api/', // @AUTHAPI@
  web: "https://revenant.studio",
  web: "https://i.revenant.studio/",
  name: 'default',
  nameAn: 'default',
  nameShort: 'default',
  nameLegal: 'default',
  gaKey: 'default', // @GAKEY@
  siteLogo: undefined,
  auth: {
    local: true,
    federated: ['google'],
    google: {
      appId:
        '370077008372-k4fvmcb357sdjjeu8q8hdnui00aphitq.apps.googleusercontent.com'
    }
  },
  // the above settings are overridden by site-specific runtime configs which
  // come in from `/assets/config/config.js` which is loaded via the <head>
  // defined in the public/index.html file; which is loaded and put into
  // window.config
  ...window.config
}

export default config
//
// const SITES = {
//   'codex.revenant.studio': 'codex',
//   'localhost:3001': 'codex',
//   'roguetrader.com': 'rt',
//   'localhost:3002': 'rt',
//   'wyrmstone.com': 'wst',
//   'localhost:3003': 'wst',
//   'enrequiem.com': 'enr',
//   'localhost:3004': 'enr'
// }
//
// // TODO: update to allow more variants, such as off-host and on-host
// const host = window.location.hostname
// let port = window.location.port
// let hostport = host
// if (host === 'localhost') {
//   hostport = host + ':' + port
// }
// let app = SITES[hostport]
// let orig_port = ':' + port
// let tls = true
// if (process.env.NODE_ENV === 'development') {
//   port = ':4000'
//   tls = false
// } else {
//   if (
//     (port === '80' && window.location.protocol === 'http') ||
//     (port === '443' && window.location.protocol === 'https')
//   ) {
//     port = ''
//   } else {
//     port = ':' + port
//   }
// }
//
// const config = {
//   app,
//   host,
//   lane: "p",
//   build: "202311221421",
//   name: 'default',
//   name_a: 'default',
//   nameShort: 'default',
//   nameLegal: 'default',
//   nav_show: {
//     icon: true,
//     text: false
//   },
//   url: {
//     http: `http${tls ? 's' : ''}://${host}${port}`,
//     ws: `ws${tls ? 's' : ''}://${host}${port}/socket`,
//     app: "https://api.revenant.studio",
//     graphql: '/graphql',
//     authapi: '/authx/v1/api/',
//     pub: `https://i.revenant.studio/`
//   },
//   auth: {
//     local: true,
//     federated: ['google'],
//     google: {
//       appId:
//         '370077008372-k4fvmcb357sdjjeu8q8hdnui00aphitq.apps.googleusercontent.com'
//     }
//   }
// }
//
// // TODO: move to global reducer
// console.log(
//   `getting http${tls ? 's' : ''}://${host}${orig_port}/codex/manifest.json`
// )
// axios
//   .get(`http${tls ? 's' : ''}://${host}${orig_port}/codex/manifest.json`)
//   .then(({ data }) => {
//     config.name = data.name
//     config.nameShort = data.nameShort
//     config.nameAn = data.nameAn
//     config.nameLegal = data.nameLegal
//   })
//
// export default config
