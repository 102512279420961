import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import config from 'constants/config'
import paths from 'constants/paths'

import Loading from 'tools/Loading'
import { toDictOn } from 'utils/array'

import GlobalContext from 'reducer/global'

import SignOnFederated from './SignOnFederated'
import SignOnLocal from './SignOnLocal'
import { TERMS } from './password'

////////////////////////////////////////////////////////////////////////////////
function Heading() {
  return (
    <div className="pt2 pt4-ns">
      <div className="pa3 flex-center">
        <div className="pa3 f6 lh-copy fw5 tc">
          {config.logo.full ? (
            <img
              src={config.logo.full}
              style={{ height: '12rem' }}
              className="mr3"
              alt={config.name}
            />
          ) : (
            config.name
          )}
        </div>
      </div>
    </div>
  )
}

const TAB_HEADERS = [
  { txt: 'Sign in', tok: 'signin' },
  { txt: 'Sign up', tok: 'signup' }
]
const TAB_HEADERS_MAP = toDictOn(TAB_HEADERS, 'tok')

////////////////////////////////////////////////////////////////////////////////
function SignForm({
  signup = false,
  children = undefined,
  heading = true,
  scroll = false
}) {
  const [status, setStatus] = useState(undefined)
  const [currentTab, setCurrentTab] = useState(signup ? 'signup' : 'signin')
  const boxPadding = 'ph3 ph4-ns'
  const elemView = useRef()
  const [{ user }] = useContext(GlobalContext)

  useEffect(() => {
    if (scroll && elemView.current !== undefined) {
      elemView.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [elemView, scroll])

  const tabClick = useCallback(
    (item, index, event) => {
      event.preventDefault()
      setStatus('')
      setCurrentTab(TAB_HEADERS[index].tok)
    },
    [setStatus]
  )

  return (
    <div className={'flex-center ' + (heading ? 'mb6 mb0-ns mt6' : '')}>
      <div className="flex items-center flex-column">
        <div ref={elemView} />
        {heading && <Heading />}
        {children}
        {user.signingIn ? <Loading /> : null}
        <div className="theme-frame flex-center mb5 mh3 mh0-l wmax8-l wmin8-l">
          <div className="w-100">
            <div className="flex">
              {TAB_HEADERS.map((item, index) => {
                return (
                  <button
                    onClick={(e) => tabClick(item, index, e)}
                    className={`bn b pa2 tc w-50 br0 f5 ${
                      item.tok !== currentTab ? 'plain' : 'clear'
                    } hover-hilight`}
                    key={index}
                    style={{ borderRadius: 0 }}
                  >
                    {item.txt}
                  </button>
                )
              })}
            </div>
            {config.auth.federated && (
              <div
                className={`${boxPadding} items-center ba b--transparent pb1 pt3 pt4-ns`}
              >
                <div className="primary mb3 tc w-100 f2 b">Sign in with:</div>
                <SignOnFederated status={status} setStatus={setStatus} />
                {config.auth.local && (
                  <div className="hrstrike pt3">
                    <div className="f2 primary b">or</div>
                  </div>
                )}
              </div>
            )}
            {config.auth.local && (
              <div className={`${boxPadding} pb1 mt3`}>
                <SignOnLocal
                  signup={currentTab === 'signup'}
                  label={TAB_HEADERS_MAP[currentTab].txt}
                  setStatus={setStatus}
                  status={status}
                />
              </div>
            )}
            <div className={`${boxPadding} tc fw2`}>
              {status && <div className="visible pa2 mt2 red">{status}</div>}
            </div>
            <div className={`${boxPadding} items-center ba b--transparent`}>
              <div className="f2 tc mv3 lh-copy ph3">
                <b>By continuing, you agree</b> {TERMS}
              </div>
              {config.auth.local && (
                <div className=" f2 tc mv3">
                  <a href={paths.password}>Forgot Password?</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignForm
