import gql from 'graphql-tag'

export const fileVars = `
  id
  subject
  path
  valid
  name
  size
  type {
    id
    mime
  }
  updatedAt
`
export const UPSERT_FILE = gql`
  mutation upsertFile($file: InputFile!) {
    upsertFile(file: $file) {
      success
      reason
      result {
        ${fileVars}
        signedUrl
      }
    }
  }
`

export const DELETE_FILE = gql`
  mutation deleteFile($file: InputFile!) {
    deleteFile(file: $file) {
      success
      reason
      result {
        id
      }
    }
  }
`
