import { Cookies } from 'react-cookie'

import { R_NOTIFY, reducer as notifyReducer } from 'tools/Notify/reducer'
import { defaultState as defaultNotify } from 'tools/Notify/reducer'
import apollo from 'utils/apollo'
import { authRequest, signonDropStates, signonInitialState } from 'utils/signon'
import { defaultUser, normalizeUser } from 'utils/user'

import { signonReducer } from 'reducer/signon/reducer'

export const cookies = new Cookies()
export const COOKIE_NAME = 'e'
const starting = cookies.get(COOKIE_NAME) || {}

////////////////////////////////////////////////////////////////////////////////
export function generateBaseGlobalState(seed) {
  return Object.freeze({
    notify: defaultNotify,
    nav: { bar: [], menu: [] },
    ...seed,
    apolloInit: false,
    apollo: apollo(() => {}),
    page: {
      hideChrome: false,
      title: '',
      theme: 'theme-dark',
      section: 'Controller',
      innerNav: undefined,
      navOverride: undefined
    },

    user: defaultUser(),
    signon: signonInitialState(),

    // OLD THINGS
    prefs: {
      nav: { gaz: 1 },
      show: {},
      filters: {},
      orgList: {
        name: ''
      },
      orgList_t: 0,
      projectList: {
        goal: 'any',
        needs: [],
        files: [],
        activity: 'quarter',
        name: ''
      },
      projectList_t: 0,
      connect: {
        name: '',
        topics: []
      },
      connect_t: 0,
      skip: true,
      ...starting
    },
    query: {},
    saving: false,
    savingKeys: {},
    // history: [window.location.pathname],
    interfaceConfig: {}
  })
}

// export const defaultBaseGlobalState = generateBaseGlobalState({})

export function resetBaseGlobalState(oldState, newSeed) {
  oldState.apollo.cache.reset()
  signonDropStates()
  authRequest('signout', {})

  // keep notify messages across state transitions
  return generateBaseGlobalState({
    notify: oldState.notify,
    nav: oldState.nav,
    ...newSeed
  })
}

export const BASE_R_GLOBAL = {
  // R_SIGNON has 0-1000
  APOLLO_RESET: 1001,
  NOTIFY: 2000,
  USER_SET: 4001,
  USER_MERGE: 4002,
  PAGE_SET: 6001,
  PAGE_NAV: 6002
  // USER_CLICKED: 2001,
  // TAGS_SET: 5001,
  // PAGE_TITLE: 6003,
  // PREFS_SET: 7001,
  // PREFS_READY: 7002,
  // PREFS_SHOW_COLS: 7004,
  // PREFS_FILTER_COLS: 7005,
  // NAV_ACTIVITY: 9000,
  // MERGE_EDITABLE: 20000,
  // MERGE: 30000
  // SET_SAVING: 10000,
}

export function baseGlobalReducer(state, { type, ...action }) {
  switch (type) {
    case BASE_R_GLOBAL.APOLLO_RESET:
      return { ...state, apolloInit: true, apollo: apollo(action.dispatch) }

    case BASE_R_GLOBAL.NOTIFY:
      return { ...state, notify: notifyReducer(state.notify, action.value) }

    case BASE_R_GLOBAL.USER_SET: {
      const user = normalizeUser(action.value, true)
      return { ...state, user }
    }

    case BASE_R_GLOBAL.USER_MERGE:
      return { ...state, user: { ...state.user, ...action.value } }

    // case R_GLOBAL.PAGE_SET: {
    //   // we want to always default some things to undefined, if they aren't
    //   // specified for a given page, so they doesn't carry from page to page
    //   const page = {
    //     ...state.page,
    //     flavor: undefined,
    //     sidebar: undefined,
    //     ...action.value
    //   }
    //   if (page.title || page.name) {
    //     document.title = `Codex${page.name ? ' - ' + page.name : ''}${
    //       page.title ? ' - ' + page.title : ''
    //     }`
    //   } else {
    //     document.title = 'Codex - Creative Tools'
    //   }
    //   return { ...state, page }
    // }
    //
    // case R_GLOBAL.PAGE_TITLE: {
    //   // variant from PAGE_SET, also sets id as title
    //   const title = action.value
    //   const page = {
    //     ...state.page,
    //     flavor: undefined,
    //     sidebar: undefined,
    //     title,
    //     id: title
    //   }
    //   return { ...state, page }
    // }

    case BASE_R_GLOBAL.PAGE_SET: {
      return {
        ...state,
        page: {
          ...state.page,
          section: undefined,
          title: undefined,
          innerNav: undefined,
          theme: undefined,
          NavOverride: undefined,
          ...action.value
        },
        // safetey net, when we change page state also kick notify
        notify: notifyReducer(state.notify, { type: R_NOTIFY.EXPIRE })
      }
    }

    case BASE_R_GLOBAL.PAGE_NAV:
      return { ...state, nav: { ...action.value } }
    // case R_GLOBAL.TAGS_SET:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       profile: action.value,
    //       ...normalizeUserParam('profile', action.value)
    //     }
    //   }
    //
    // case R_GLOBAL.PREFS_SET: {
    //   const prefs = {
    //     ...state.prefs,
    //     [action.key]: { ...state.prefs[action.key], ...action.value }
    //   }
    //   // cookies.set(COOKIE_NAME, prefs, { path: '/' })
    //   return { ...state, prefs: { ...prefs, skip: true } }
    // }
    //
    // case R_GLOBAL.PREFS_READY:
    //   return { ...state, prefs: { ...state.prefs, skip: false } }
    //

    case BASE_R_GLOBAL.PREFS_SHOW_COLS: {
      const show = state.prefs.show || {}
      const { key, ...rest } = action
      show[key] = { ...rest }
      const prefs = { ...state.prefs, show: { ...show } }
      cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs } }
    }

    case BASE_R_GLOBAL.PREFS_FILTER_COLS: {
      let filters = state.prefs.filters || {}
      const { key, reset, merge, set } = action
      if (reset) {
        filters = {}
      } else if (merge) {
        filters[key] = { ...(filters[key] || {}), ...merge }
      } else if (set) {
        filters[key] = set
      }
      const prefs = { ...state.prefs, filters: { ...filters } }
      cookies.set(COOKIE_NAME, prefs, { path: '/' })
      return { ...state, prefs: { ...prefs } }
    }

    // case R_GLOBAL.NAV_ACTIVITY: {
    //   let { menu, bar, value } = action
    //   if (menu) {
    //     menu = navActivity(state.nav.menu, menu, value)
    //     return { ...state, nav: { ...state.nav, menu } }
    //   }
    //   bar = navActivity(state.nav.bar, bar, value)
    //   return { ...state, nav: { ...state.nav, bar } }
    // }
    //
    // case R_GLOBAL.MERGE_EDITABLE:
    //   return { ...state, ...action }

    default:
      return signonReducer(state, type, action)
  }
}

// function navActivity(list, key, value) {
//   return list.map((item) => {
//     if (item.label === key) {
//       return { ...item, activity: value }
//     }
//     return item
//   })
// }
