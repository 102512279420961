import React, { useContext, useEffect } from 'react'

import NotifyPopup from 'tools/Notify/Popup'

import GlobalContext from 'reducer/global'
import { R_GLOBAL } from 'reducer/global'

import Background from './Background'
import Footer from './Footer'
import NavBar from './NavBar'

import { bar, menu } from 'app/codex/Root/NavBar/config'

function Root({ children }) {
  const [, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    dispatch({ type: R_GLOBAL.PAGE_NAV, value: { menu, bar } })
  }, [dispatch])

  return (
    <>
      <NotifyPopup />
      <div className="body">
        <Background />
        {children}
        <NavBar />
      </div>
      <Footer />
    </>
  )
}

export default Root
