import React from 'react'
import { createRoot } from 'react-dom/client'

import config from 'constants/config'

import { SignonProvider } from 'components/Signon/Provider'
import { GlobalProvider } from 'reducer/global'

export default async function startup(conf, Router, props) {
  Object.assign(config, conf)
  Object.freeze(config)
  console.log(`BUILD=${config.build} APP=${config.app} PUB=${config.pub} WEB=${config.web}`)

  const container = document.getElementById('root')
  const root = createRoot(container)

  // only include high level providers and configuration things here
  root.render(
    <GlobalProvider>
      <SignonProvider>
        <Router {...props} />
      </SignonProvider>
    </GlobalProvider>
  )
}
