import axios from 'axios'

import { UPSERT_FILE } from 'tools/Files/graphql'

// TODO: add width/height
export function uploadFile({
  apollo,
  subject,
  mime,
  name,
  size,
  refType,
  refId,
  id = undefined,
  src,
  onSuccess,
  onFailure = (e) => {},
  debug = false
}) {
  debug = true
  return getFileHandle({
    apollo,
    subject,
    refId,
    refType,
    debug,
    name,
    size,
    mime
  }).then(
    ({
      data: {
        upsertFile: { success, reason, result }
      }
    }) => {
      debug && console.log('upload:getFileHandle =>', { success, reason, result })
      if (success) {
        const { id, signedUrl } = result
        // we have a handle to send to, now upload it...
        return pushFile({ src, dst: signedUrl, mime, debug })
          .then((result) => {
            debug && console.log('upload:pushFile =>', result)
            // we have uploade the file, now mark it as valid on our backend
            return validFile({ apollo, id, debug }).then(
              ({
                data: {
                  upsertFile: { success, reason, result }
                }
              }) => {
                debug &&
                  console.log('upload:validFile =>', { success, reason, result })
                if (success) {
                  return onSuccess(result)
                } else {
                  return onFailure(reason)
                }
              }
            )
          })
          .catch((error) => {
            debug && console.log('upload:onFailure =>', error)
            return onFailure(`${error}`)
          })
      } else {
        return onFailure(reason)
      }
    }
  )
}

// some of these abstractions are just to make the readability above easier
function getFileHandle({
  apollo,
  subject,
  refId,
  debug,
  size,
  refType,
  mime,
  name
}) {
  debug && console.log(`upload:getFileHandle subject=${subject}, refId=${refId})`)
  return apollo.mutate({
    mutation: UPSERT_FILE,
    variables: {
      file: { subject, refId, genUrl: true, size, refType, mime, name }
    }
  })
}

function validFile({ apollo, id, debug }) {
  debug && console.log(`upload:validFile id=${id})`)
  return apollo.mutate({
    mutation: UPSERT_FILE,
    variables: { file: { id, valid: true } }
  })
}

function pushFile({ src, dst, mime, debug }) {
  const options = {
    headers: {
      'Content-Type': mime
    }
  }

  debug && console.log('upload:pushFile src=', src, options)
  return axios.put(dst, src, options)
}
