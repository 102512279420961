import React, { useCallback, useContext, useEffect } from 'react'

import notify from 'tools/Notify'

import GlobalContext from 'reducer/global'

import style from './index.module.scss'
import { EXPIRE_TIME } from './reducer'

export function Notify() {
  const [
    {
      notify: { expireFlag, popups }
    },
    dispatch
  ] = useContext(GlobalContext)

  useEffect(() => {
    // DONT CLEAR TIMEOUT IN CLEANUP
    // https://react.dev/reference/react/useEffect#my-cleanup-logic-runs-even-though-my-component-didnt-unmount
    setTimeout(() => {
      notify.expire(dispatch)
    }, EXPIRE_TIME)
  }, [expireFlag, dispatch])

  const hide = useCallback((id) => notify.remove(dispatch, id), [dispatch])

  if (!popups.length) return null

  return (
    <div className={style.msgWrap}>
      {popups.map((msg) => (
        <div
          key={msg.id}
          className={`${style.msg} ${style[msg.type]}`}
          onClick={() => {
            hide(msg.id)
            if (msg.onClick) {
              msg.onClick(dispatch)
            }
          }}
        >
          <div
            className={style.close}
            onClick={(e) => {
              e.stopPropagation()
              hide(msg.id)
            }}
          >
            <i className="fas fa-times" />
          </div>
          {msg.content}
        </div>
      ))}
    </div>
  )
}

export default Notify
