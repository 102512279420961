import paths from 'constants/paths'

export const bar = [
  {
    to: 'https://revenant.studio/studio/',
    label: 'Studio',
    icon: 'user-pen',
    target: '_self'
  },
  {
    to: 'https://revenant.studio/books/',
    label: 'Books',
    icon: 'book-open',
    target: '_self'
  },
  {
    to: 'https://revenant.studio/social/',
    label: 'Social',
    icon: 'comments',
    target: '_self'
  }
]

export const menu = [
  {
    to: paths.preferences,
    label: 'Preferences'
  },
  {
    divider: true,
    id: 'admin-div',
    authz: 'admin_user'
  },
  {
    to: paths.pageView,
    label: 'Docs',
    icon: 'file',
    authz: 'doc_admin'
  },
  {
    to: paths.tableView,
    label: 'Tables',
    icon: 'table',
    authz: 'doc_admin'
  },
  {
    to: paths.admFile,
    label: 'Files',
    icon: 'file',
    authz: 'file_admin'
  },
  {
    to: paths.admSummary,
    label: 'Admin',
    // todo: flip to mentor action
    authz: 'admin_user'
  },
  {
    to: paths.story,
    label: 'Stories',
    icon: 'lightbulb',
    authz: 'admin_user'
  },
  {
    divider: true,
    id: 'signout-div'
  },
  {
    to: paths.signout,
    label: 'Sign Out'
  }
]
