import React, { useEffect, useState } from 'react'

import { readableError } from 'utils/error'
import { loadingBabble } from 'utils/loadingBabble'

export default function LoadingInline({
  children = undefined,
  babble = true,
  className = ''
}) {
  return (
    <div className="flex">
      {children ? children : babble ? loadingBabble() : null}
      <div className={`loading-inline ${className}`} />
    </div>
  )
}

export function LoadingTop({ children = undefined, babble = true }) {
  return (
    <div className="relative w-100">
      <div className="absolute top-0 mt2 w-100">
        <div className="flex w-100 flex justify-center">
          {children ? children : babble ? loadingBabble() : null}
          <div className="loading-inline" />
        </div>
      </div>
    </div>
  )
}
export function Loading({ children = undefined, babble = true }) {
  return (
    <div className="flex-center h-100 v-100">
      <div className="flex">
        {children ? children : babble ? loadingBabble() : null}
        <div className="loading-inline" />
      </div>
    </div>
  )
}

// for when loading may take longer
export function LoadingOverlay(props) {
  const { children, onlyFull, noText = false } = props
  const [showFull, updateShowFull] = useState(false)
  const [retries, updateRetries] = useState(0)
  let showText = [
    <div key="1">
      {children || loadingBabble()}
      {retries ? ' (retry ' + retries + ')' : null}
    </div>
  ]
  if (noText) {
    showText = []
  }

  // DOC: this effect tracks a mount/unmount flag, so it does not update
  // state if the component is not active (mounted)
  useEffect(() => {
    let isMounted = true
    setTimeout(() => {
      if (isMounted) {
        updateShowFull(true)
      }
    }, 300)
    setTimeout(() => {
      if (isMounted) {
        updateShowFull(false)
        updateRetries(retries + 1)
      }
    }, 10000)

    // return the function to "unmount"
    return () => {
      isMounted = false
    }
  })

  if (showFull) {
    return (
      <div className="loading-overlay">
        <div className="loading"></div>
        <div className="tc pv2 pv4-m pv6-l white">{showText}</div>
        <div className="loading-body"></div>
      </div>
    )
  } else {
    if (onlyFull) {
      return <></>
    }
    return (
      <div className="flex">
        {showText}
        <div className="loading-inline" />
      </div>
    )
  }
}

export function ErrorBox(props) {
  return (
    <div className="flex justify-center red b">
      {readableError(props.children)}
    </div>
  )
}

export { readableError, LoadingInline }
