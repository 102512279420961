import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import notifier from 'tools/Notify'
import { R_SIGNON, getAccessToken } from 'utils/signon'

import { LOAD_USER } from 'components/User/graphql'
import GlobalContext, {
  R_GLOBAL //, assertActiveOrg
} from 'reducer/global'

export function useTitle(title) {
  const [
    {
      page: { title: ptitle }
    },
    dispatch
  ] = useContext(GlobalContext)

  useEffect(() => {
    if (ptitle !== title) {
      dispatch({ type: R_GLOBAL.PAGE_TITLE, value: title })
    }
  }, [ptitle, title, dispatch])
}

export function usePage(pageData, deps) {
  const [, dispatch] = useContext(GlobalContext)
  useEffect(() => {
    dispatch({
      type: R_GLOBAL.PAGE_SET,
      value: pageData
    })
  }, [dispatch, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}

// returns URLSearchParams object with the current query parameters
export function useQueryParams() {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

export function useUrlFragment() {
  const location = useLocation()
  return location.hash.replace('#', '')
}

export function useFeature(flag) {
  const qp = useQueryParams()
  return qp.getAll('flag').includes(flag)
}

////////////////////////////////////////////////////////////////////////////////
export function windowPreferredTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'theme-dark'
    : 'theme-light'
}

export function isThemeDark() {
  return document.body.classList.contains('theme-dark')
}

export function useTheme() {
  const [state, dispatch] = useContext(GlobalContext)

  useEffect(() => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: 'theme-light' } })
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          dispatch({
            type: R_GLOBAL.PAGE_SET,
            value: { theme: e.matches ? 'theme-dark' : 'theme-light' }
          })
        })
    }
  }, [dispatch])

  const theme =
    state.page.theme || state.user.settings.theme || windowPreferredTheme()

  useEffect(() => {
    document.body.classList.remove('theme-dark')
    document.body.classList.remove('theme-light')
    document.body.classList.add(theme)
  }, [theme])
}

////////////////////////////////////////////////////////////////////////////////
export function useSignon() {
  const [, notifyDispatch] = useContext(notifier.Context)
  const [state, dispatch] = useContext(GlobalContext)

  // initialize apollo
  useEffect(() => {
    dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
  }, [dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { reload, refresh, error: signon_error } = state.signon
  useEffect(() => {
    if (refresh) {
      getAccessToken(dispatch)
    }
  }, [refresh, dispatch])

  // Fetch profile after done signing in or if `reload` changes
  useEffect(() => {
    let isMounted = true
    if (reload) {
      state.apollo
        .query({
          query: LOAD_USER,
          variables: { id: 'self' },
          fetchPolicy: 'network-only'
        })
        .then(({ data: { users } }) => {
          if (isMounted) {
            if (users.success) {
              dispatch({ type: R_GLOBAL.USER_SET, value: users.result })
            } else {
              notifier.error(users.reason)
            }
          }
        })
        .catch(notifier.onCatch(dispatch))
    }
    return () => {
      isMounted = false
    }
  }, [reload, state.apollo, dispatch])

  // convert signon errors to notify
  useEffect(() => {
    if (signon_error) {
      notifier.error(notifyDispatch, signon_error)
      dispatch({ type: R_SIGNON.ERROR_CLEAR })
    }
  }, [signon_error, dispatch, notifyDispatch])
}
