import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { split } from '@apollo/client/link/core'
import { onError } from '@apollo/client/link/error'
import { fromPromise } from '@apollo/client/link/utils'
import { hasSubscription } from '@jumpn/utils-graphql'

import config from 'constants/config'

// import { readableError } from 'tools/Loading'
import debug from 'utils/debug'
import {
  currentAccessTokenRetrieve,
  getAccessToken,
  isCurrentAccessTokenExpired
} from 'utils/signon'

import absintheSocketLink from './absinthe-socket-link'

const httpLink = createHttpLink({
  uri: `${config.app}${config.graphql}`,
  // I prefer this is 'include' but httpLink returns a network error in prod
  // if this is the setting.  something to look into later...
  // credentials: 'same-origin'
  credentials: 'include'
})

const transportLink = split(
  (operation) => hasSubscription(operation.query),
  absintheSocketLink,
  httpLink
)

// some state management to pause execution while we refresh tokens
let IS_REFRESHING = false
let PENDING_REQUESTS = []
function pendingRequestsCallback() {
  if (PENDING_REQUESTS.length > 0) {
    debug('[apollo:errorLink] pendingRequestsCallback()', PENDING_REQUESTS)
    PENDING_REQUESTS.map((callback) => callback())
    PENDING_REQUESTS = []
  }
}
function pendingRequestsResolve(resolve) {
  PENDING_REQUESTS.push(() => resolve())
}
function handleError(error, dispatch) {
  if (PENDING_REQUESTS.length > 0) {
    debug('[apollo:errorLink] handleError()', PENDING_REQUESTS)
    PENDING_REQUESTS = []
    // authError({ dispatch, msg: readableError(error) })
  }
}
function finishResolving() {
  IS_REFRESHING = false
}

function getHeader({ headers = {}, dispatch }) {
  const { token } = currentAccessTokenRetrieve(dispatch)
  if (!token) {
    console.warn('[apollo:authLink] asyncPausableGetHeader() "token missing"')
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
}

async function asyncPausableGetHeader({ headers = {}, dispatch }) {
  if (IS_REFRESHING) {
    return new Promise((resolve) => {
      pendingRequestsResolve(() => resolve(getHeader({ headers, dispatch })))
    })
  }
  if (isCurrentAccessTokenExpired()) {
    return getAccessToken(dispatch)
      .then(({ access_token: fresh_token }) => {
        if (fresh_token) {
          pendingRequestsCallback()
          return getHeader({ headers, dispatch })
        }
      })
      .catch((error) => handleError(error, dispatch))
      .finally(finishResolving)
  }
  return getHeader({ headers, dispatch })
}

function refreshOrQueue({ forward, operation, dispatch }) {
  debug('[apollo:errorLink] refreshOrQueue()')
  const headers = operation.getContext().headers
  const forward$ = fromPromise(asyncPausableGetHeader({ headers, dispatch }))
  IS_REFRESHING = true
  return forward$.flatMap(() => forward(operation))
}

export function apollo(dispatch) {
  const authLink = setContext(async (_, { headers }) =>
    asyncPausableGetHeader({ headers, dispatch })
  )

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      debug('[apollo:errorLink]', [graphQLErrors, operation, forward])
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.message) {
            case 'permission denied':
            case 'Unauthenticated':
            case 'Unauthorized':
            case 'Error: GraphQL error: permission denied':
            case 'GraphQL error: permission denied':
              return refreshOrQueue({ forward, operation, dispatch })
            default:
              debug('[apollo:errorLink] unrecognized error', err)
          }
        }
      } else {
        debug('[apollo:errorLink]', [networkError, operation, forward])
      }
    }
  )

  return new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, transportLink]),
    cache: new InMemoryCache()
  })
}

export default apollo
